import CityCountry from 'CityCountry';

/**
 * Dealer locator functionality
 *
 * @memberOf module:feature/Dealer
 * @requires module:project/Common.CityCountry
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class DealerLocatorEntrance {

    /**
     * Initializes all config and vars.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     */
    constructor($element) {

        // elements
        this.$element = $element;

        // config
        this.locale = this.$element.attr('data-locale');
        this.selectedCountryCode = this.$element.attr('data-country');
        this.locatorUrl = this.$element.attr('data-url');

        // buttons
        this.buttons = {
            'SEARCH': this.$element.find('.btn.btn--dealer-search')
        };

        // controls
        this.controls = {
            'COUNTRY': this.$element.find('.form-control.form-control--country'),
            'CITY': this.$element.find('.form-control.form-control--city')
        };

        // init city/country dependency class
        this.cityCountry = new CityCountry(this.$element, {
            language: this.locale,
            selectedCountryCode: this.selectedCountryCode
        });

        // selections
        this.country = false;
        this.countryCode = false;
        this.city = false;
    }

    /**
     * Initializes City Country dependencies.
     */
    init() {

        // init city/country dependencies
        this.cityCountry.init();

        // listen for country/city changes
        this.cityCountry.addListener('countrychanged', (selectedCountryCode, selectedCountryName) => {
            this.country = selectedCountryName;
            this.countryCode = selectedCountryCode;
        });
        this.cityCountry.addListener('citychanged', (selectedCity) => {
            this.city = selectedCity;
        });

        // handle button click
        this.buttons.SEARCH.on('click', this.handleSearchClick.bind(this));

    }

    /**
     * Create params and redirects page.
     * @param {jQueryEvent} e Event.
     */
    handleSearchClick(e) {
        e.preventDefault();
        let param = '';
        if (this.country) {
            param += `?country=${encodeURIComponent(this.country)}`;
        }
        if (this.countryCode) {
            param += `&code=${encodeURIComponent(this.countryCode)}`;
        }
        if (this.city) {
            param += `&city=${encodeURIComponent(this.city)}`;
        }

        window.location.href = `${this.locatorUrl}${param}`;
    }
}


// register to Component Handler
window.ComponentHandler.register({
    constructor: DealerLocatorEntrance,
    classAsString: 'DealerLocatorEntrance',
    cssClass: 'js-dealerlocator-entrance'
});

export default DealerLocatorEntrance;