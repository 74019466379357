import EventEmitter from 'EventEmitter';
import AjaxHandler from 'AjaxHandler';

/**
 * Updates city list in select inputs based on selected country.
 *
 * @memberOf module:project/Common
 * @extends module:project/Common.EventEmitter
 * @requires module:project/Common.AjaxHandler
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class CityCountry extends EventEmitter {
    /**
     * Initializes controls and vars.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     * @param {object} options Options and configs to exteand and override default config.
     */
    constructor($element, options = {}) {
        super();

        // elements
        this.$element = $element;

        const config = {
            language: 'en-GB',
            selectedCountryCode: '',
            selectedCity: '',
        };

        // extend/override config
        this.config = {
            ...config,
            ...options,
        };

        // api
        this.apiBaseUrl = '/api/feature/dealer';

        // controls
        this.controls = {
            COUNTRY: this.$element.find('.form-control.form-control--country'),
            CITY: this.$element.find('.form-control.form-control--city'),
        };

        // loaders
        this.loaders = {
            COUNTRY: this.$element.find('#form-loader-country').hide(),
            CITY: this.$element.find('#form-loader-city').hide(),
        };

        // default disable
        this.disableControls();

        // ajax handler
        this.ajax = new AjaxHandler({ type: 'POST' });
    }

    /**
     * Fetches countries and renders country select input.
     * If default selected value, update cities select too.
     */
    init() {
        return new Promise((resolve) => {
            // control event handlers
            this.controls.COUNTRY.on('change.citycountry', this.handleChangeCountry.bind(this));
            this.controls.CITY.on('change.citycountry', this.handleChangeCity.bind(this));

            this.disableControls(true, false);
            this.loaders.COUNTRY.show();
            this.getCountries()
                .then((countryData) => {
                    const defaultOption = this.controls.COUNTRY.html();
                    const selectedOption = this.config.selectedCountryCode;
                    const optionsHtml = this.renderCountrySelectOptions(defaultOption, selectedOption, countryData);
                    this.controls.COUNTRY.html(optionsHtml);
                    this.loaders.COUNTRY.fadeOut(200, () => {
                        this.enableControls(true, false);
                    });
                    this.emit('countrychanged', selectedOption, this.controls.COUNTRY.find('option:selected').text());

                    if (selectedOption !== '') {
                        this.updateCities(this.getIso2CountryCode(selectedOption)).then(() => {
                            if (this.config.selectedCity !== '') {
                                this.emit('citychanged', this.config.selectedCity);
                            }
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                })
                .catch((err) => {
                    console.warn(`No countries found. Reason: ${err}`);
                    resolve();
                });
        });
    }

    /**
     * Returns ISO-2 code from concatenated CountryCode string.
     * @param {string} isoCode I.e. NL
     */
    getIso2CountryCode(isoCode) {
        const countryCode = isoCode.split('-')[0];
        return countryCode;
    }

    /**
     * Returns ISO-3 code from concatenated CountryCode string.
     * @param {string} isoCode I.e. NLD
     */
    getIso3CountryCode(isoCode) {
        const countryCode = isoCode.split('-')[1];
        return countryCode;
    }

    /**
     * Disables city control and creates current selected coutry code.
     * Emits event to listeners.
     * @param {jQueryEvent} e Event.
     */
    handleChangeCountry(e) {
        this.updateCities(this.getIso2CountryCode($(e.currentTarget).val())).then(() => {
            this.emit('countrychanged', $(e.currentTarget).val(), $(e.currentTarget).find('option:selected').text());
        });
    }

    /**.
     * Emits event to listeners.
     * @param {jQueryEvent} e Event.
     */
    handleChangeCity(e) {
        this.emit('citychanged', $(e.currentTarget).val());
    }

    /**
     * Updates city select input based on selected country code.
     * @param {string} countryCode Selected Country Code.
     */
    updateCities(countryCode) {
        return new Promise((resolve) => {
            this.disableControls(false, true);
            this.loaders.CITY.show();
            this.getCities(countryCode)
                .then((cityData) => {
                    const defaultOption = this.controls.CITY.find('option').first()[0].outerHTML;
                    const selectedOption = this.config.selectedCity;
                    const optionsHtml = this.renderCitySelectOptions(defaultOption, selectedOption, cityData);
                    this.controls.CITY.html(optionsHtml);
                    this.loaders.CITY.fadeOut(200, () => {
                        this.enableControls(false, true);
                        resolve();
                    });
                })
                .catch((err) => {
                    console.warn(`No cities found. Reason: ${err}`);
                    resolve();
                });
        });
    }

    /**
     * Renders country select options.
     * @param {array} data Array with country objects.
     * @return {string} Html string of options.
     */
    renderCountrySelectOptions(defaultOption, selectedValue, data) {
        let options = [defaultOption];
        data.forEach((country) => {
            let selectedOption = '';
            if (selectedValue === `${country.CountryCode}-${country.Iso3}`) {
                selectedOption = ' selected';
            }
            options.push(
                `<option value="${country.CountryCode}-${country.Iso3}"${selectedOption}>${country.Country}</option>`
            );
        });
        return options.join('');
    }

    /**
     * Renders city select options.
     * @param {array} data Array with country objects.
     * @return {string} Html string of options.
     */
    renderCitySelectOptions(defaultOption, selectedValue, data) {
        let options = [defaultOption];
        data.forEach((city) => {
            let selectedOption = '';
            if (selectedValue === city.ReferenceCity) {
                selectedOption = ' selected';
            }
            options.push(`<option value="${city.ReferenceCity}"${selectedOption}>${city.ReferenceCity}</option>`);
        });
        return options.join('');
    }

    /**
     * Enables controls.
     * @param {boolean} [county=true] If true country control gets enabled.
     * @param {boolean} [city=true] If true city control gets enabled.
     */
    enableControls(country = true, city = true) {
        if (country) {
            this.controls.COUNTRY.prop('disabled', false);
        }
        if (city) {
            this.controls.CITY.prop('disabled', false);
        }
    }

    /**
     * Disables controls.
     * @param {boolean} [county=true] If true country control gets disabled.
     * @param {boolean} [city=true] If true city control gets disabled.
     */
    disableControls(country = true, city = true) {
        if (country) {
            this.controls.COUNTRY.prop('disabled', true);
        }
        if (city) {
            this.controls.CITY.prop('disabled', true);
        }
    }

    /**
     * Returns countries from API.
     */
    getCountries() {
        return new Promise((resolve, reject) => {
            this.ajax
                .request({
                    url: `/${this.config.language}${this.apiBaseUrl}/getcountries`,
                })
                .then((response) => {
                    if (response.Success) {
                        resolve(response.Countries);
                    } else {
                        reject(response.Reason);
                    }
                });
        });
    }

    /**
     * Returns a list of cities based on given countrycode.
     * @param {string} [cCode] Country code.
     */
    getCities(cCode = false) {
        return new Promise((resolve, reject) => {
            let countryCode = '';
            if (cCode) {
                countryCode = JSON.stringify({ countryCode: cCode });
            } else {
                countryCode = JSON.stringify({ countryCode: '' });
            }

            this.ajax
                .request({
                    url: `/${this.config.language}${this.apiBaseUrl}/getreferencecities`,
                    data: countryCode,
                })
                .then((response) => {
                    if (response.length > 0) {
                        resolve(response);
                    } else {
                        reject('Error, no cities found!');
                    }
                    // if (response.Success) {
                    //     resolve(response.Cities);
                    // } else {
                    //     reject(response.Reason);
                    // }
                });
        });
    }
}

export default CityCountry;
